<template>
  <div class="m-falling-blocks">
    <div class="m-falling-blocks__header global-padding">
      <div class="m-falling-blocks__header-title">
        <AText
          tag="h2"
          :size-lg="{ size: 50, line: 46.5 }"
          :size-sm="{ size: 32, line: 30 }"
        >
          БОЛЬШЕ, ЧЕМ МУЗЫКА
        </AText>
      </div>
      <div class="m-falling-blocks__header-text">
        <AText
          :size-lg="{ size: 18, line: 20 }"
          :size-sm="{ size: 18, line: 20 }"
          font-family="vk"
          weight="regular"
          :uppercase="false"
        >
          Строим десятки развлекательных зон, чтобы создать незабываемое впечатление для каждого.
        </AText>
      </div>
    </div>

    <div class="m-falling-blocks__canvas" />

    <div
      v-if="tooltip.visible"
      class="tooltip"
      :style="tooltipStyle"
    >
      <AText
        :size-lg="{ size: 18, line: 20 }"
        :size-sm="{ size: 14, line: 18 }"
      >
        {{ tooltip.text }}
      </AText>

      <svg
        class="tooltip__tail"
        :class="{'flip' : isTooltipOverViewport}"
        width="18"
        height="15"
        viewBox="0 0 18 15"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path d="M0.274847 13.7789C7.36722 7.17562 3.07031 0.0561523 3.07031 0.0561523L17.3919 11.8808C17.3919 11.8808 8.95689 16.7136 0.274847 13.7789Z" fill="#171717" />
      </svg>
    </div>
  </div>
</template>

<script setup lang="ts">
import Matter from 'matter-js';
import AText from '~/components/atoms/text/a-text.vue';

export interface Shape { width: number, height: number, img: string, url: string, tooltipText: string }

interface Props {
  shapes: Shape[],
}

const props = defineProps<Props>();

const device = useDevice();

const isMobile = computed(() => device.isMobile.value || device.isTablet.value);

let observer: IntersectionObserver | null = null;
const engineRef = ref<Matter.Engine | null>(null);
const runnerRef = ref<Matter.Runner | null>(null);
const renderRef = ref<Matter.Render | null>(null);

const tooltip = ref({
  visible: false,
  text: '',
  x: 0,
  y: 0,
});

const currentWidth = computed(() => tooltip.value.visible ? tooltip.value.x : 0);
const isTooltipOverViewport = computed(() => tooltip.value.visible && (currentWidth.value + (isMobile.value ? 50 : 150)) > window.innerWidth);
const TOOLTIP_WIDTH = 270;

const tooltipStyle = computed(() => {
  const factor = isMobile.value ? -50 : TOOLTIP_WIDTH / 2;

  let left = tooltip.value.x;
  if (tooltip.value.x + TOOLTIP_WIDTH > window.innerWidth) {
    left = window.innerWidth - TOOLTIP_WIDTH;
  }

  return {
    top: `${tooltip.value.y}px`,
    left: isTooltipOverViewport.value ? `${left - factor}px` : `${left}px`,
  };
});

const init = () => {
  const {
    Engine,
    Render,
    Bodies,
    World,
    Runner,
    Composite,
    MouseConstraint,
    Mouse,
  } = Matter;

  const imageHeight = 220; // Высота изображения
  const rowSpacing = window.innerWidth / 6; // Расстояние между изображениями в ряду

  const canvasSize = {
    width: window.innerWidth,
    height: isMobile.value ? 750 : 1000,
  };

  // create engine
  const engine = Engine.create();
  const world = engine.world;

  // create renderer
  const render = Render.create({
    element: document.querySelector('.m-falling-blocks__canvas')!,
    engine,
    options: {
      width: canvasSize.width,
      height: canvasSize.height,
      background: 'transparent',
      wireframes: false,
      showAngleIndicator: false,
      pixelRatio: window.devicePixelRatio,
    },
  });

  Render.run(render);

  // create runner
  const runner = Runner.create();
  Runner.run(runner, engine);

  // safe links
  engineRef.value = engine;
  runnerRef.value = runner;
  renderRef.value = render;

  const wallWidth = isMobile.value ? 10 : 200; // Ширина стен

  // add static walls
  Composite.add(world, [
    // Bodies.rectangle(canvasSize.width / 2, -25, canvasSize.width, 50, { isStatic: true }), // Top wall
    Bodies.rectangle(canvasSize.width / 2, canvasSize.height, canvasSize.width, 50,
      { isStatic: true, render: { visible: false } }), // Нижняя стена

    Bodies.rectangle(canvasSize.width + wallWidth / 2, canvasSize.height / 2, wallWidth * 2.3, canvasSize.height * 3,
      { isStatic: true, render: { visible: false } }), // Правая стена

    Bodies.rectangle(-wallWidth / 2, canvasSize.height / 2, wallWidth * 2.3, canvasSize.height * 3,
      { isStatic: true, render: { visible: false } }), // Левая стена
  ]);

  // images
  const images = props.shapes;

  // "Воздух" между фигурами
  images.forEach((image) => {
    image.width = image.width + 10;
    image.height = image.height + 10;
  });

  // Split images into 4 groups of rows
  const imagesRow1 = images.slice(0, 5);
  const imagesRow2 = images.slice(5, 9);
  const imagesRow3 = images.slice(9, 13);
  const imagesRow4 = images.slice(13);

  // Settings
  // const rowHeight = 150; // Высота между рядами
  // const imageWidth = 220; // Ширина изображения

  const clickableBodies = []; // Для хранения объектов с телами и их URL

  const addImageRow = (images: [{ width: number, height: number, img: string, url: string, tooltipText: string }], startY: number) => {
    images.forEach((image, index) => {
      const randomOffsetX = (Math.random() - 0.5) * 50; // Случайное смещение по X (-25 до +25)
      const randomOffsetY = (Math.random() - 0.5) * 20; // Случайное смещение по Y (-10 до +10)

      const x = rowSpacing * (index + 1) + randomOffsetX;
      const y = startY + randomOffsetY;

      const body = Bodies.rectangle(x, y,
        isMobile.value ? image.width / 2 : image.width,
        isMobile.value ? image.height / 2 : image.height, {
          density: 0.0005,
          frictionAir: 0.01,
          restitution: 0.2,
          friction: 0.03,
          render: {
            sprite: {
              texture: image.img,
              xScale: isMobile.value ? 0.5 : 1,
              yScale: isMobile.value ? 0.5 : 1,
            },
          },
        });

      clickableBodies.push({ body, url: image.url, tooltipText: image.tooltipText });

      Composite.add(world, body);
    });
  };

  // Add rows
  addImageRow(imagesRow1, -imageHeight);
  addImageRow(imagesRow2, -imageHeight * 2);
  addImageRow(imagesRow3, -imageHeight * 4);
  addImageRow(imagesRow4, -imageHeight * 6);

  // Add mouse control
  const canvasElement = render.canvas;

  // Разрешить прокрутку при взаимодействии с canvas
  canvasElement.addEventListener(
    'wheel',
    (event) => {
      event.stopImmediatePropagation(); // Останавливаем только текущую обработку
    },
    { passive: false } // для совместимости с браузерами
  );

  const mouse = Mouse.create(canvasElement);
  const mouseConstraint = MouseConstraint.create(engine, {
    mouse,
    constraint: {
      stiffness: 0.2,
      render: {
        visible: false,
      },
    },
  });

  // Обработчики событий для мобильных устройств
  mouseConstraint.mouse.element.removeEventListener('touchmove', mouseConstraint.mouse.mousemove);
  mouseConstraint.mouse.element.removeEventListener('touchstart', mouseConstraint.mouse.mousedown);
  mouseConstraint.mouse.element.removeEventListener('touchend', mouseConstraint.mouse.mouseup);

  Composite.add(world, mouseConstraint);

  Matter.Events.on(mouseConstraint, 'mousemove', (event) => {
    const mousePosition = event.mouse.position;
    let hovered = false;

    clickableBodies.forEach(({ body, tooltipText }) => {
      const isHovered =
        Matter.Bounds.contains(body.bounds, mousePosition) &&
        Matter.Query.point([body], mousePosition).length > 0;

      if (isHovered) {
        body.render.sprite.xScale = isMobile.value ? 0.55 : 1.1;
        body.render.sprite.yScale = isMobile.value ? 0.55 : 1.1;

        // Показываем tooltip
        tooltip.value = {
          x: mousePosition.x + 200, // Смещение
          y: mousePosition.y + 50,
          text: tooltipText,
          visible: true, // true что бы включить tooltip
        };

        hovered = true;
      }
      else {
        body.render.sprite.xScale = isMobile.value ? 0.5 : 1;
        body.render.sprite.yScale = isMobile.value ? 0.5 : 1;
      }
    });

    if (!hovered) {
      tooltip.value.visible = false;
    }
  });

  /**
   * На случай, если надо будет клики по шейпам
   * */
  // // Cобытие клика
  // Matter.Events.on(mouseConstraint, 'mouseup', (event) => {
  //   const mousePosition = event.mouse.position;
  //
  //   // Проверяем, попадает ли клик по какому-либо из тел
  //   clickableBodies.forEach(({ body, url }) => {
  //     if (
  //       Matter.Bounds.contains(body.bounds, mousePosition) &&
  //       Matter.Query.point([body], mousePosition).length > 0
  //     ) {
  //       window.open(url, '_blank'); // Открываем ссылку в новой вкладке
  //     }
  //   });
  // });

  Render.lookAt(render, {
    min: { x: 0, y: 0 },
    max: { x: canvasSize.width, y: canvasSize.height },
  });
};

const cleanupMatter = () => {
  if (renderRef.value) {
    Matter.Render.stop(renderRef.value);
    renderRef.value.canvas.remove();
    renderRef.value.textures = {};
  }

  if (runnerRef.value) {
    Matter.Runner.stop(runnerRef.value);
  }

  if (engineRef.value) {
    Matter.World.clear(engineRef.value.world, false);
    Matter.Engine.clear(engineRef.value);
  }

  engineRef.value = null;
  runnerRef.value = null;
  renderRef.value = null;
};

const initObserver = () => {
  if (observer) return;

  observer = new IntersectionObserver(
    (entries) => {
      const entry = entries[0];
      if (entry.isIntersecting && !engineRef.value) {
        init();
      }
    },
    { threshold: 0.2 });

  observer.observe(document.querySelector('.m-falling-blocks__canvas')!);
};

onMounted(() => {
  initObserver();
});

onUnmounted(() => {
  if (observer) {
    observer.disconnect();
    observer = null;
  }
  cleanupMatter();
});
</script>

<style scoped lang="postcss" src="./style.css" />
